body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#sps {
  position: relative;
  top: -6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.about {
  position: relative;
  z-index: 5;
  width: 100%;
  color: white;
}
.about:hover
{
  background-color: #29004e;
}
.about-text {
  margin: 0;
}
.about h2 {
  color: white;
}
.about .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}
.about .row {
  width: 100%;
  height: 50%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #33035c;
  border-radius: 2rem;
}
.about .row img {
  align-self: flex-start;
  background: black;
  width: 170px;
  position: relative;
  bottom: -65px;
}
@media (max-width: 900px) {
  .about .row {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .about .row img {
    width: 200px;
  }
}
